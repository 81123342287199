import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap';
import html2canvas from 'html2canvas';
import { useSelector, useDispatch } from 'react-redux';
import { setConfig, selectConfig } from '../../state/configSlice';
import { selectGroups, addGroup } from '../../state/groupsSlice';
import { selectItems, addItem } from '../../state/itemsSlice';
import './TopBar.scss';

function TopBar() {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const groups = useSelector(selectGroups);
  const items = useSelector(selectItems);

  const capture = () => {
    const roadmap = document.getElementById('roadmap');

    html2canvas(roadmap, {
      useCORS: true,
      allowTaint: true,
      letterRendering: true,
      onrendered: function (canvas) {
        const ctx = canvas.getContext('2d');
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
      }
    }).then(function (canvas) {
      const link = document.createElement('a');
      link.setAttribute('download', 'roadmap.png');
      link.setAttribute(
        'href',
        canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      );
      link.click();
    });
  };

  const exportItems = () => {
    console.log('groups', groups);
    console.log('items', items);

    if (!groups.length) {
      return;
    }

    const content = JSON.stringify({
      groups: groups,
      items: items
    });

    const a = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = 'roadmap.json';
    a.click();
    a.remove();
  };

  const importItems = () => {
    const input = document.createElement('input');
    input.type = 'file';

    input.addEventListener('cancel', () => {
      console.error('File upload cancelled');
      input.remove();
    });

    input.addEventListener('change', () => {
      if (input.files.length === 1) {
        const file = input.files[0];
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function (evt) {
          const fileContent = evt.target.result;
          const fileContentJson = JSON.parse(fileContent);

          if (fileContentJson && fileContentJson.groups) {
            fileContentJson.groups.forEach(group =>
              dispatch(addGroup({ group }))
            );
          }

          if (fileContentJson && fileContentJson.items) {
            fileContentJson.items.forEach(item => {
              dispatch(addItem({ item }));
            });
          }

          input.remove();
        };
        reader.onerror = function (evt) {
          console.error('File read error');
          input.remove();
        };
      } else {
        input.remove();
      }
    });

    input.click();
  };

  return (
    <div>
      <Navbar className="topbar">
        <NavbarBrand href="/">ROADMAP</NavbarBrand>

        <Nav className="mw-auto">
          <NavItem>
            <NavLink
              onClick={() =>
                dispatch(setConfig({ isPreview: !config.isPreview }))
              }
              className="text-dark btn"
            >
              <i
                className={`bi bi-toggle-${config.isPreview ? 'on' : 'off'}`}
              ></i>
              &nbsp; Preview
            </NavLink>
          </NavItem>
          <NavItem>
            <Button onClick={capture} color="dark">
              Capture
            </Button>
          </NavItem>
          &nbsp;
          <NavItem>
            <Button onClick={importItems} color="dark">
              Import
            </Button>
          </NavItem>
          &nbsp;
          <NavItem>
            <Button onClick={exportItems} color="dark">
              Export
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
}

export default TopBar;
