import { createSlice, createSelector } from '@reduxjs/toolkit';
import { selectItems } from './itemsSlice';

const initialState = {
  groups: []
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    addGroup: (state, action) => {
      state.groups = [...state.groups, action.payload.group];
    },
    removeGroup: (state, action) => {
      state.groups = state.groups.filter(
        group => group.id !== action.payload.id
      );
    },
    updateGroup: (state, action) => {
      state.groups = state.groups.map(group => ({
        ...group,
        name: group.id === action.payload.id ? action.payload.name : group.name
      }));
    }
  }
});

export const selectGroups = state => state.groups.groups;
const selectGroupId = (state, groupId) => groupId;

export const selectGroupsMapping = createSelector(
  [selectGroups, selectItems],
  (groups, items) => {
    return groups.map(group => ({
      ...group,
      items: items.filter(item => item.groupId === group.id)
    }));
  }
);

export const selectGroupById = createSelector(
  [selectGroups, selectGroupId],
  (groups, groupId) => groups[groupId]
);

export const { addGroup, removeGroup, updateGroup } = groupsSlice.actions;
export default groupsSlice.reducer;
