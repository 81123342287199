import { configureStore } from '@reduxjs/toolkit';
import groupsReducer from './groupsSlice';
import itemsSlice from './itemsSlice';
import configSlice from './configSlice';

export const store = configureStore({
  reducer: {
    groups: groupsReducer,
    items: itemsSlice,
    config: configSlice
  }
});
