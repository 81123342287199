import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  config: { isPreview: false }
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = { ...state.config, ...action.payload };
    }
  }
});

export const selectConfig = state => state.config.config;

export const { setConfig } = configSlice.actions;
export default configSlice.reducer;
